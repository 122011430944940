// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { API } from './api.js'
import Lodash from 'lodash'
import i18n from './i18n'
import colormap from './components/colormap.js'
import PseudoTooltip from './components/PseudoTooltip'
import LandingHelptip from './landing/HelptipLanding'
import { clickOutsideDirective } from '@/plugins/directives'
import { initTracking } from './utils/tracking'

initTracking()

let pathParts = window.location.pathname.split('/').slice(1)

// Check the loanguage, given the url parts
let lang
switch (pathParts[0]) {
  case 'en':
    lang = 'en'
    break
  case 'de':
    lang = 'de'
    break
  case '':
    // When no language is given, redirect to English
    window.location = '/en/'
    break
  default:
    throw Error(`Unrecognized language ${pathParts[0]}`)
}

window.language = lang
window.currentPath = pathParts[1]
i18n.locale = window.language

// Check which landing page was requested
let Page
switch (pathParts[1]) {
  case '':
  case undefined:
    Page = require('@/landing/Landing').default
    break
  case 'managed':
    Page = require('@/landing/Managed').default
    break
  case 'features':
    Page = require('@/landing/Features').default
    break
  case 'use-cases':
    switch (pathParts[2]) {
      case 'review-analysis':
        Page = require('@/landing/use-cases/Review').default
        break
      case 'transactional-cx':
        Page = require('@/landing/use-cases/CX').default
        break
      case 'survey':
        Page = require('@/landing/use-cases/Survey').default
        break
      case 'brand-awareness':
        Page = require('@/landing/use-cases/Brand').default
        break
      case 'text-analytics':
        Page = require('@/landing/use-cases/Analytics').default
        break
      default:
        Page = require('@/landing/UseCases').default
        break
    }
    break

  case 'campaigns':
    switch (pathParts[2]) {
      case 'tailwind':
        Page = require('@/landing/Tailwind').default
        // Override the language for this page, as it's only available in English
        window.language = 'en'
        i18n.locale = window.language
        break
    }
    break
  case 'case-studies':
    switch (pathParts[2]) {
      case 'factworks':
        Page = require('@/landing/case-studies/Factworks').default
        break
      case 'rugby':
        Page = require('@/landing/case-studies/Rugby').default
        break
      case 'flixbus':
        Page = require('@/landing/case-studies/Flixbus').default
        break
      case 'lufthansa':
        Page = require('@/landing/case-studies/Lufthansa').default
        break
      case 'beiersdorf':
        Page = require('@/landing/case-studies/Beiersdorf').default
        break
      case 'miro':
        Page = require('@/landing/case-studies/Miro').default
        break
      case 'dhl':
        Page = require('@/landing/case-studies/DHL').default
        break
      case 'teufel':
        Page = require('@/landing/case-studies/Teufel').default
        break
      case 'ebay':
        Page = require('@/landing/case-studies/Ebay').default
        break
      case 'brack':
        Page = require('@/landing/case-studies/Brack').default
        break
      case 'ikea':
        Page = require('@/landing/case-studies/Ikea').default
        break
      case 'joyn':
        Page = require('@/landing/case-studies/Joyn').default
        break
      case 'dr-schar':
        Page = require('@/landing/case-studies/DrSchar').default
        break
      case 'tegut':
        Page = require('@/landing/case-studies/Tegut').default
        break
      case 'link':
        Page = require('@/landing/case-studies/Link').default
        break
      case 'cx-ai':
        Page = require('@/landing/case-studies/Cx').default
        break
      case 'iwd':
        Page = require('@/landing/case-studies/IWD').default
        break
      default:
        Page = require('@/landing/CaseStudies').default
        break
    }
    break
  case 'pricing':
    Page = require('@/landing/Pricing').default
    break
  case 'about':
    Page = require('@/landing/About').default
    break
  case 'register':
    Page = require('@/landing/Register').default
    break
  case 'register-legacy':
    Page = require('@/landing/RegisterLegacy').default
    break
  case 'market-research':
    Page = require('@/landing/MarketResearch').default
    break
  case 'consultants':
    Page = require('@/landing/Consultants').default
    break
  case 'professionals':
    Page = require('@/landing/Professionals').default
    break
  // case 'login':
  //   Page = require('@/landing/Login').default
  //   break
  case 'terms-and-conditions':
  case 'privacy-policy':
  case 'supported-languages':
  case 'meeting-booked':
  case 'email-unsubscribed':
    Page = require('@/landing/Info').default
    break
  default:
    throw Error(`Unrecognized path ${pathParts[1]}`)
}

Vue.config.productionTip = false
Vue.prototype.$api = API
Vue.prototype.$_ = Lodash
Vue.prototype.$color = colormap
Vue.prototype.$escapeHtml = function (unsafe) {
  return unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;')
}

Vue.component('v-tooltip', PseudoTooltip)
Vue.component('helptip', LandingHelptip)

Vue.directive('c-click-outside', clickOutsideDirective)

let LandingComponent = Vue.component('landing', Page)
/* eslint-disable no-new */
let landing = new LandingComponent({
  el: '#mountpoint',
  i18n
})

window.app = landing