<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <!-- <img
              src="@/assets/landing/case-studies/ikea/logo.svg?data"
              class="case-study__logo"
              style="height: 25px;"
              alt="ikea"
            > -->
            <h2 class="case-study__title">
              How IKEA Uses Over 3 Million Feedback Comments in 40 Languages to Meet Customer Needs Like Never Before
            </h2>
            <img
              src="@/assets/landing/case-studies/ikea/hero.jpg"
              class="case-study__image w-100"
              alt="Brack"
              style="height: auto;"
            >
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img
                class="mr-2"
                src="@/assets/landing/case-studies/ikea/tim.jpg"
                alt="Tim"
                style="object-fit: cover;"
              >
              <div class="ml-2">
                <div class="case-study__person__name">
                  Tim Stierman
                </div>
                <div class="case-study__person__position">
                  Digital Product Leader @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.ikea.com/"
                  >
                    IKEA
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              IKEA, a global leader in furniture and home solutions, is equally committed to providing outstanding customer experiences. To understand and improve customer satisfaction across its 63 markets in over 40 languages, IKEA partnered with Caplena, a text analytics platform powered by machine learning. Tim Stierman, Digital Product Leader at IKEA’s Customer Experience Insights Platform, explains how Caplena has transformed IKEA’s approach to customer feedback, making insights faster and more accurate.
            </div>

            <h5 class="case-study__title">
              Key Results:
            </h5>
            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">1.</span> <b>3 Million Customer Feedbacks Processed Monthly: </b> Uncovering real-time insights that drive actionable improvements and boost customer satisfaction globally
                </div>
                <div class="mt-1">
                  <span class="mr-2">2.</span> <b>16,000 Employees Using Worldwide: </b> Empowering employees to make data-driven decisions that enhance customer experience
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">3.</span> <b>40+ Languages Analyzed: </b> Delivering consistent, high-quality insights across diverse markets to ensure IKEA meets customer needs everywhere
                </div>
              </div>
            </div>
            <h5 class="case-study__title">
              The Challenge
            </h5>
            <div class="case-study__text">
              IKEA’s global reach made analyzing customer feedback a challenge. Before, IKEA used a lexicon-based method to classify topics, which was labor-intensive and inconsistent across languages. Creating and maintaining dictionaries manually across 40+ languages resulted in unreliable insights, while AI-powered automation led to the following benefits:
            </div>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <b>Reducing Inefficiency: </b> Automated analysis eliminates time-consuming manual work, freeing up teams to focus on impactful tasks
                </div>
                <div class="mt-1">
                  <b>Ensuring Consistency: </b>  AI-powered accuracy across languages provides reliable insights for every market
                </div>
                <div class="mt-1">
                  <b>Building Trust: </b> Improved data quality empowers teams with the confidence to make informed decisions
                </div>
              </div>
            </div>

            <div class="case-study__text">
              A large portion of customer feedback was also being grouped into a broad “General” category, which hid specific insights and made precise decision-making harder. For example, feedback like “Eating at IKEA” was often classified too generally, with the lexicon method missing finer details such as food quality or wait times. This made analysis and action more challenging.
            </div>

            <img
              src="@/assets/landing/case-studies/ikea/image_3.jpg"
              class="case-study__image mb-2"
              alt="ikea"
            >

            <div class="case-study__text">
              With Caplena’s AI-powered text analytics capabilities, IKEA overcame these challenges by automating and refining topic classification across all languages. This shift led to more precise insights and improved decision-making.
            </div>

            <h5 class="case-study__title">
              Transition to Caplena
            </h5>

            <div class="case-study__text">
              Seeking a more scalable solution, IKEA launched a detailed Request for Proposal process, beginning with 10 vendors. After testing the top three, Caplena was chosen for its superior accuracy and efficiency. This success led IKEA to pilot Caplena in key markets. With positive results, IKEA rolled out Caplena globally.
            </div>

            <h5 class="case-study__title">
              Implementation & Results
            </h5>

            <div class="case-study__text">
              IKEA’s customer feedback process is thorough, gathering data from digital platforms, customer support centers, and open web sources. They focus on three main feedback areas:
            </div>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">1</span> <b>Satisfaction Rating: </b> Overall customer satisfaction.
                </div>
                <div class="mt-1">
                  <span class="mr-2">2</span> <b>Closed Business Questions: </b> Specific questions related to IKEA’s business.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">3</span> <b>Open-Ended Questions: </b> Insights into the reasons behind customer satisfaction or dissatisfaction.
                </div>
              </div>
            </div>

            <div class="case-study__quote">
              Caplena’s AI and machine learning allow us to understand and respond to customer feedback in real-time, putting the customer first in every market, regardless of language. It’s no longer just about gathering data but truly meeting customer needs in ways we couldn’t before," shares Tim Stierman.
            </div>

            <div
              class="position-relative hover w-100"
              @click="toggleVideo('https://www.youtube.com/embed/H7fCWBOOgPw')"
            >
              <img
                src="@/assets/landing/case-studies/ikea/tim.png"
                class="case-study__image"
                alt="IKEA tim"
                style="max-height: 400px; object-position: top;"
              >
              <button class="play play--subtle absolute-center flex-center-center hover">
                <play-icon :size="35" />
              </button>
            </div>
            <label class="case-study__image-text" />

            <h5 class="case-study__title mt-2">
              Case Example: IKEA’s Food Experience
            </h5>
            <div class="case-study__text">
              Food is a crucial part of IKEA’s business, generating nearly €2 billion in annual turnover. However, in a pilot study on food in a selected market, customer satisfaction with the food experience fell below the global average. By using Caplena to analyze feedback, IKEA identified key areas for improvement and took the following actions:
            </div>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">•</span> <b>Checkout Waiting Time: </b> Implemented a new payment technology solution.
                </div>
                <div class="mt-1">
                  <span class="mr-2">•</span> <b>Value for Money: </b> Halted price increases and introduced family member offers.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Cleanliness: </b> Improved the appeal and cleanliness of food areas.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Co-worker Friendliness: </b> Enhanced training to ensure a more welcoming atmosphere.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Coffee Selection: </b> Improved the coffee offering to meet Italian customers' high expectations.
                </div>
              </div>
            </div>

            <div class="d-flex">
              <img
                src="@/assets/landing/case-studies/ikea/image_1.jpg"
                class="case-study__image mb-2"
                style="object-fit: contain; width: 60%;"
                alt="ikea"
              >
              <img
                src="@/assets/landing/case-studies/ikea/image_2.jpg"
                class="case-study__image mb-2"
                alt="ikea"
                style="object-fit: contain; width: 40%;"
              >
            </div>
            <label class="case-study__image-text" />

            <div class="case-study__text">
              As a result, the overall satisfaction score for IKEA’s food experience in this market rose by 10%, highlighting the direct impact of feedback analysis on business improvements.
            </div>

            <h5 class="case-study__title mt-2">
              Future Directions
            </h5>
            <div class="case-study__text">
              Looking forward, IKEA plans to combine customer feedback with broader business data to gain a more complete understanding of customer needs. They also aim to harness AI and conversational AI to improve their ability to interpret feedback quickly and accurately, allowing for faster and more precise responses to customer concerns.
            </div>

            <h5 class="case-study__title mt-2">
              Conclusion
            </h5>
            <div class="case-study__text">
              IKEA’s work with Caplena has transformed how they handle customer feedback, going beyond just saving time. By automating and accurately analyzing feedback in different languages, IKEA now gets clear insights that help improve customer experience in every market. This change allows IKEA to better understand and respond to what customers need, showing their commitment to excellent service worldwide and helping them grow by connecting with customers on a deeper level.
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                IKEA
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                IKEA, founded in 1943, is a Swedish brand known for affordable, stylish furniture and home goods. Its flat-pack designs make assembly easy and reduce costs. IKEA also offers popular Swedish-inspired food, like meatballs and pastries, in its restaurants. Focused on sustainability, IKEA aims to be climate-positive by 2030.
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                2023
              </div>
              <div class="case-study__company__subtitle">
                Text comments analyzed
              </div>
              <div class="case-study__company__text">
                3 million monthly
              </div>
              <div class="case-study__company__subtitle">
                Employees using data-driven insights
              </div>
              <div class="case-study__company__text">
                16,000
              </div>
              <div class="case-study__company__subtitle">
                Languages analyzed for global consistency
              </div>
              <div class="case-study__company__text">
                40+
              </div>
              <div class="case-study__company__subtitle">
                Increase in food satisfaction after targeted improvements
              </div>
              <div class="case-study__company__text">
                10%
              </div>
              <div class="case-study__company__subtitle">
                Markets where IKEA operates with localized insights
              </div>
              <div class="case-study__company__text">
                63
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
    <transition name="fade">
      <div class="overlay" v-if="showVideo" @click="toggleVideo" />
    </transition>
    <transition name="fade">
      <div v-if="!!showVideo" id="video" class="modal-input modal-input--video" style="height: 55vh;">
        <iframe
          width="100%"
          height="100%"
          :src="`${showVideo}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        />
      </div>
    </transition>
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'
import PlayIcon from 'vue-material-design-icons/Play.vue'

export default {
  components: {
    LandingScaffold,
    Trial,
    PlayIcon
  },

  data () {
    return {
      showVideo: false
    }
  },
  computed: {},
  mounted () {},
  methods: {
    toggleVideo (link) {
      if (this.showVideo) this.showVideo = false
      else this.showVideo = link
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />
